<template>
  <div class="frame game-results-page">
    <a-page-header
      :title="$t('global.menu.GameResults')"
      @back="() => $router.go(-1)"
    >
    </a-page-header>
    <div class="page-content">
      <div class="search-bar">
        <a-form-model ref="searchForm" :model="form" :rules="rules">
          <div class="search-group">
            <Currency :inputCurrency.sync="form.currency"
                      :inputMaster.sync="form.master"
                      :showCurrency="auth_role === 'master' || auth_role === 'agent' ? false : true"
                      :showMaster="auth_role === 'master' || auth_role === 'agent' ? false : true"
                      :showAgent="false"
                      :currencyRequired="true"
                      :masterRequired="masterRequired"/>
            <a-form-model-item ref="arena_no" :label="$t('global.common.arena')" prop="arena_no">
              <a-select
                  show-search
                  placeholder=""
                  :filter-option="filterOption"
                  v-model="form.arena_no"
              >
                <a-select-option v-for="(arena, i) in arenaList" :value="arena.id" :key="i">
                  {{ arena.arena_no }}({{ arena.id }})
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="round_id" :label="$t('global.common.round_id')" prop="round_id">
              <a-input
                v-model="form.round_id"
              />
            </a-form-model-item>
            <a-form-model-item ref="fight_id" :label="$t('global.common.fight_id')" prop="fight_id">
              <a-input
                v-model="form.fight_id"
              />
            </a-form-model-item>
            <a-form-model-item ref="game_type" :label="$t('global.common.game_type')" prop="game_type">
              <a-select
                  v-model="form.game_type">
                <a-select-option v-for="game_type in activeGameType" :value="game_type.value">
                  {{ $t(`global.game_type.${game_type.label}`) }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="side" :label="$t('global.common.winner')" prop="side">
              <a-select
                  v-model="form.side">
                <a-select-option v-for="winner in gameResultWinner" :value="winner.value" :key="winner.value">
                  {{ winner.label }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="status" :label="$t('global.common.status')" prop="status">
              <a-select v-model="form.status">
                <a-select-option v-for="status in roundsStatus" :value="status" :key="status">{{ $t(`GameResultsPage.status_list.${status}`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="odds_type" :label="$t('global.common.odds_type')" prop="odds_type">
              <a-select v-model="form.odds_type" mode="multiple" :show-arrow="true">
                <a-select-option v-for="odds_type in oddsTypes" :value="odds_type.value" :key="odds_type.value">{{ $t(`global.common.odds_type_list.${odds_type.label}`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="date_type" :label="$t('global.common.date_type')" prop="date_type">
              <a-select v-model="form.date_type">
                <a-select-option v-for="typeItem in dateTypes" :value="typeItem" :key="typeItem">{{ $t(`global.common.date_type_list.${typeItem}`) }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item required prop="date">
              <a-date-picker
                v-model="form.date"
                :default-value="form.date"
                show-date
                type="date"
                placeholder="Select Date"
                @change="dateChange"
              />
            </a-form-model-item> -->
            <!-- <div class="search-btns">
              <a-button type="primary" icon="search" :loading="searchLoading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
            </div> -->
          </div>
          <div class="search-group">
            <Datetime :input.sync="form.date"
                      :periodDefault.sync="periodDefault"
                      :preciseTime="form.preciseTime"/>
            <div class="search-btns">
              <a-button type="primary" icon="search" :loading="loading" @click="onSearch">{{ $t('global.action.search') }}</a-button>
              <a-button :disabled="loading" @click="resetForm">{{ $t('global.action.reset') }}</a-button>
            </div>
          </div>
        </a-form-model>
      </div>
      <div class="dashboard-cards" v-if="dashboard_card_show">
        <div class="dashboard-card">
          <a-card hoverable>
            <a-card-meta :title="$t('global.common.total_user_bet')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/BetUsers.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_round_user_count | formatNumber }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card hoverable>
            <a-card-meta :title="$t('global.common.total_bet_count')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/BetRecords.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_round_count | formatNumber }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card hoverable>
            <a-card-meta :title="$t('global.common.total_bet_amount')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/betAmount.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_round_amount | formatNumber }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card hoverable>
            <a-card-meta
              :title="$t('global.common.total_valid_bet_amount')"
            >
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/ValidBet.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_round_valid_amount | formatNumber }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card">
          <a-card hoverable>
            <a-card-meta :title="$t('global.common.total_platform_profit')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/PlatformProfit.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_platform_profit | formatNumberWithPoint }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
        <div class="dashboard-card" v-if="is_merchant_account">
          <a-card hoverable>
            <a-card-meta :title="$t('global.common.total_rate_amount')">
              <a-avatar
                shape="square"
                slot="avatar"
                :src="require('images/commission.svg')"
              />
              <template slot="description">
                <div class="card-num">{{ total_rate_amount | formatNumberWithPoint }}</div>
              </template>
            </a-card-meta>
          </a-card>
        </div>
      </div>
      <div class="ant-card">
        <div class="ant-card-body">
          <div class="table-control">
            <a-popover trigger="click" placement="bottomRight">
              <template slot="content">
                <div class="table-filter-content">
                  <a-checkbox class="table-filter-check"
                              v-for="(item, index) in columns"
                              :key="item.dataIndex"
                              :defaultChecked="item.filterType"
                              :disabled="item.disabledFilter"
                              @click="columnsChange(index, item)"
                              :value="item.dataIndex">
                    <template v-if="item.slots.title.includes('side_')">
                      {{ getSideTitle(item.slots.title) }}
                    </template>
                    <template v-else>
                      {{ item.singleI18n ? $t(`GameResultsPage.${item.slots.title}`) : $t(`global.common.${item.slots.title}`) }}
                    </template>
                  </a-checkbox>
                </div>
              </template>
              <a-button icon="filter"></a-button>
            </a-popover>
          </div>
          <a-table
            bordered
            size="small"
            :columns="columns.filter( item => item.filterType || item.filterType === undefined)"
            :data-source="results"
            :pagination="pagination"
            :loading="loading"
            :row-key="results => results.id"
            @change="handleTableChange"
          >
            <template v-for="(column,index) in columns">
              <span :slot="column.slots.title">
                <div v-if="column.slots.title === 'fight_id'">
                  {{ $t(`global.common.game_date`)}}
                </div>
                <div v-if="column.slots.title === 'round_id'">
                  {{ $t(`global.common.arena`)}}
                </div>

                <template v-if="column.slots.title.includes('side_')">
                    {{ getSideTitle(column.slots.title) }}
                </template>
                <template v-else>
                    {{ column.singleI18n ? $t(`GameResultsPage.${column.slots.title}`) : $t(`global.common.${column.slots.title}`) }}
                </template>
                
              </span>
            </template>
            <template slot="round_id" slot-scope="round_id, row">
              <div>
                {{ row['arena_no'] }}
              </div>
              {{ round_id }}
            </template>
            <template slot="fight_id" slot-scope="fight_id, row">
              <div>
                {{ row['game_day'] | formatDayToUTC8 }}
              </div>
              {{ fight_id }}
            </template>
            <template slot="odds_type" slot-scope="odds_type, row">
              <div>
                {{ row['odds_type'] }}
              </div>
            </template>
            <template slot="rate" slot-scope="rate">
              {{ rate }}%
            </template>
            <template slot="formatTimes" slot-scope="times">
              <div class="table-time">{{ times | formatTimes }}</div>
            </template>
            <template slot="side" slot-scope="side">
              <div class="table-bet" v-if="dashboard_card_show">
                <span class="table-bet-title">{{ $t('global.common.total_bet_amount') }}:</span>
                <span class="table-bet-num num-lg">
                  <template v-if="side">{{ side.total_bet | formatNumberWithPoint }}</template>
                  <template v-else>{{ "-" }}</template>
                </span>
              </div>
              <div class="table-bet" v-if="dashboard_card_show">
                <span class="table-bet-title">{{ $t('global.common.valid_amount') }}:</span>
                <span class="table-bet-num">
                  <template v-if="side">{{ side.valid_bet | formatNumberWithPoint }}</template>
                  <template v-else>{{ "-" }}</template>
                </span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">{{ $t('global.common.odd') }}:</span>
                <span class="table-bet-num">
                  <template v-if="side">{{ side.odd | formatNumberWithPoint }}</template>
                  <template v-else>{{ "-" }}</template>
                </span>
              </div>
            </template>
            <template slot="game_type" slot-scope="game_type">
                {{$t(`global.game_type.${game_type}`)}}
            </template>
            <template slot="winner" slot-scope="winner, row">
              <div class="table-winner" :data-side="winner ? GAME_TYPE_SIDE[row.game_type][winner] : ''">
                {{ winner ? $t(`global.fight_result.${GAME_TYPE_SIDE[row.game_type][winner]}`) : '' | formatString}}
              </div>
            </template>
            <template slot="bet_info" slot-scope="bet_info">
              <div class="table-bet">
                <span class="table-bet-title">{{ $t('GameResultsPage.time') }}:</span>
                <span class="table-time num-lg">{{ bet_info.time | formatTimes }}</span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">{{ $t('global.common.operator') }}:</span>
                <span class="table-bet-num">{{ bet_info.operator | formatString }}</span>
              </div>
              <!-- <div class="table-time">{{ open }}</div> -->
            </template>
            <template slot="cancel_bet_info" slot-scope="cancel_bet_info">
              <div class="table-bet">
                <span class="table-bet-title">{{ $t('GameResultsPage.time') }}:</span>
                <span class="table-time num-lg">{{ cancel_bet_info.time | formatTimes }}</span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">{{ $t('global.common.operator') }}:</span>
                <span class="table-bet-num">{{ cancel_bet_info.operator | formatString }}</span>
              </div>
              <div class="table-bet">
                <span class="table-bet-title">{{ $t('GameResultsPage.reason') }}:</span>
                <span v-if="cancel_bet_info.cancel_reason === null" class="table-bet-num"></span>
                <span v-else class="table-bet-num">{{ $t(`GameResultsPage.cancel_reasons.${cancel_bet_info.cancel_reason}`) }}</span>
              </div>
            </template>
            <template slot="formatNumberWithPoint" slot-scope="amount">
              <div>{{ amount | formatNumberWithPoint }}</div>
            </template>
          </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGameResult, getDashboardCard, getArenaList, getMerchantRole } from '@/../api';
import Datetime from '@/../src/components/datetime_picker';
import Currency from '@/../src/components/currency_selection';
import storage, { IDENTITY } from '@/../storage';
import { cloneDeep } from 'lodash';
import { GAME_TYPE_SIDE } from '@/../tools/config';
import { filterDuplicateData } from '@/../tools/common';

const gameColunmsIndex = {
  all: ['meron', 'red3', 'wala', 'white3', 'draw'],
  normal: ['meron', 'wala', 'draw'],
  apple: ['meron', 'red3', 'wala', 'white3']
}

const merchamtColumns = [
  {
    slots: { title: "link_master" },
    dataIndex: "link_master",
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'round_id' },
    dataIndex: 'id',
    scopedSlots: { customRender: 'round_id' },
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'fight_id' },
    dataIndex: 'fight_no',
    scopedSlots: { customRender: 'fight_id' },
    width: 120,
    filterType: true,
  },
  {
    slots: { title: 'game_type' },
    dataIndex: 'game_type',
    scopedSlots: { customRender: 'game_type' },
    align: 'center',
  },
  {
    slots: { title: 'odds_type' },
    dataIndex: 'odds_type',
    scopedSlots: { customRender: 'odds_type' },
    width: 120,
    filterType: true,
  },
  // 改成動態調整
  // {
  //   slots: { title: 'meron' },
  //   dataIndex: 'meron',
  //   scopedSlots: { customRender: 'side' },
  //   filterType: true,
  // },
  // {
  //   slots: { title: 'wala' },
  //   dataIndex: 'wala',
  //   scopedSlots: { customRender: 'side' },
  //   filterType: true,
  // },
  // {
  //   slots: { title: 'draw' },
  //   dataIndex: 'draw',
  //   scopedSlots: { customRender: 'side' },
  //   filterType: true,
  // },
  {
    slots: { title: 'platform_profit' },
    dataIndex: 'platform_profit',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
  },
  {
    slots: { title: 'winner' },
    dataIndex: 'winner',
    align: 'center',
    scopedSlots: { customRender: 'winner' },
    filterType: true,
  },
  {
    slots: { title: 'rate' },
    dataIndex: 'rate',
    scopedSlots: { customRender: 'rate' },
    align: 'center',
    filterType: true,
  },
  {
    slots: { title: 'open_round' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'rate_amount' },
    dataIndex: 'rate_amount',
    align: 'right',
    scopedSlots: { customRender: 'formatNumberWithPoint' },
    filterType: true,
  },
  {
    slots: { title: 'open_bet_at' },
    dataIndex: 'open',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'close_bet_at' },
    dataIndex: 'close',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'declare_at' },
    dataIndex: 'declare',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'reload_at' },
    dataIndex: 'reload',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'settled_at' },
    dataIndex: 'settled',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
  },
  {
    slots: { title: 'cancel_at' },
    dataIndex: 'cancel',
    scopedSlots: { customRender: 'cancel_bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
];

const agentColumns = [
  {
    slots: { title: 'round_id' },
    dataIndex: 'id',
    scopedSlots: { customRender: 'round_id' },
    filterType: true,
    disabledFilter: true,
  },
  {
    slots: { title: 'fight_id' },
    dataIndex: 'fight_no',
    scopedSlots: { customRender: 'fight_id' },
    width: 120,
    filterType: true,
  },
  {
    slots: { title: 'game_type' },
    dataIndex: 'game_type',
    scopedSlots: { customRender: 'game_type' },
    align: 'center',
  },
  {
    slots: { title: 'odds_type' },
    dataIndex: 'odds_type',
    scopedSlots: { customRender: 'odds_type' },
    width: 120,
    filterType: true,
  },
  // 改成動態調整
  // {
  //   slots: { title: 'meron' },
  //   dataIndex: 'meron',
  //   scopedSlots: { customRender: 'side' },
  //   filterType: true,
  // },
  // {
  //   slots: { title: 'wala' },
  //   dataIndex: 'wala',
  //   scopedSlots: { customRender: 'side' },
  //   filterType: true,
  // },
  // {
  //   slots: { title: 'draw' },
  //   dataIndex: 'draw',
  //   scopedSlots: { customRender: 'side' },
  //   filterType: true,
  // },
  {
    slots: { title: 'winner' },
    dataIndex: 'winner',
    align: 'center',
    scopedSlots: { customRender: 'winner' },
    filterType: true,
  },
  {
    slots: { title: 'open_round' },
    dataIndex: 'created_at',
    scopedSlots: { customRender: 'formatTimes' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'open_bet_at' },
    dataIndex: 'open',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'close_bet_at' },
    dataIndex: 'close',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'declare_at' },
    dataIndex: 'declare',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'reload_at' },
    dataIndex: 'reload',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
  {
    slots: { title: 'settled_at' },
    dataIndex: 'settled',
    scopedSlots: { customRender: 'bet_info' },
    align: 'right',
    filterType: true,
  },
  {
    slots: { title: 'cancel_at' },
    dataIndex: 'cancel',
    scopedSlots: { customRender: 'cancel_bet_info' },
    align: 'right',
    filterType: true,
    singleI18n: true,
  },
];

export default {
  components: {
    Datetime,
    Currency,
  },
  data() {
    return {
      auth_role: storage.getItem(IDENTITY), //取得當前角色
      data: [],
      pagination: {
        current: 1,
        pageSize: 10, // 默认每页显示数量
        showSizeChanger: true, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `Total ${total} items`, // 显示总数
        showSizeChange: (current, pageSize) => (this.pageSize = pageSize), // 改变每页数量时更新显示
        total: 0
      },
      periodDefault: 'today',
      expand: false,
      loading: false,
      GAME_TYPE_SIDE,
      form: {
        // date: this.moment(new Date(), 'YYYY-MM-DD'),
        date: [new Date(), new Date()],
        date_type: 'GameDate',
        arena_no: '',
        odds_type: [],
        preciseTime: 'time',  // 是否啟用 hh:mm:ss
        currency: '',
        master: null,
        round_id: '',
        fight_id: '',
        side: '',
        game_type: '',
      },
      rules: {
        round_id: [{ pattern: new RegExp(/^[0-9]+$/), message: () => this.$t('global.message.must_be_number'), trigger: 'submit' }],
        fight_id: [{ pattern: new RegExp(/^[0-9]+$/), message: () => this.$t('global.message.must_be_number'), trigger: 'submit' }],
        master: [{required: false}]
      },
      results: [],
      total_round_amount: 0,
      total_round_count: 0,
      total_round_user_count: 0,
      total_round_valid_amount: 0,
      total_platform_profit: 0,
      total_rate_amount: 0,
      roundsStatus: [
        'initial',
        // 'betting',
        // 'fighting',
        // 'last_call',
        // 'finished',
        // 'reloaded',
        'earn_locked',
        'cancel',
      ],
      oddsTypes: [
        {
          value: '0',
          label: 'rake'
        },
        {
          value: '1',
          label: 'malay'
        },
        {
          value: '2',
          label: 'static'
        },
      ],
      dateTypes: [
        'GameDate',
        'OpenRound',
      ],
      gameTypes: [
        {
          value: 'cockfight',
          label: 'cockfight'
        },
        {
          value: 'spider',
          label: 'spider'
        },
        {
          value: 'color_game',
          label: 'color_game'
        },
        {
          value: 'apple',
          label: 'apple'
        },
        {
          value: 'up_down',
          label: 'up_down'
        },
      ],
      arenaList: [],
      dashboard_card_show: false,
      is_merchant_account: false,
      checkCurrency: '',
      tableGameType: '',
    };
  },
  mounted(){
    getMerchantRole()
        .then((data)=>{
          this.dashboard_card_show = data.data.role['GameResults']? data.data.role['GameResults'].dashboard_card : false;
          this.is_merchant_account = data.data.is_merchant_account;
        })
  },
  computed: {
    masterRequired() {
      const { auth_role, form, periodDefault } = this;
      const yesterday = this.moment().subtract(1, 'days').format('YYYY-MM-DD');
      const beforeYesterday = this.moment(form.date[0], 'YYYY-MM-DD').isBefore(yesterday);
      const requiredPeriod = ['last_week', 'this_week', 'last_month', 'this_month'];
      return auth_role === 'merchant' && (beforeYesterday || requiredPeriod.includes(periodDefault)) ? true : false;
    },
    currency(){
      return this.form.currency;
    },
    activeGameType(){
      //把arena list裡重複的game type移除
      const onlyOneTypeAry = [];
      
      this.arenaList.forEach(listItem=>{
        if(!onlyOneTypeAry.includes(listItem.game_type)){
          onlyOneTypeAry.push(listItem.game_type)
        }
      })

      //回傳arena list裡目前有的game type
      return this.gameTypes.filter(typeItem=> onlyOneTypeAry.includes(typeItem.label));

    },
    activeSide(){
      const side = {};
      //藉由active game type去取得各個遊戲種類的 side value
      this.activeGameType.forEach(activeGameTypeItem=>{
        const itemKeys = GAME_TYPE_SIDE[activeGameTypeItem.label];

        for(let keyItem in itemKeys){
          //如果是 continue 則跳過
          if(keyItem === 'cancel') continue;

          //如果還沒生成陣列，則生成陣列
          if(side[keyItem] === undefined){
            side[keyItem] = [];
          }

          //如果已有存在同樣的值，則不進到陣列
          if(!side[keyItem].includes(itemKeys[keyItem])){
            side[keyItem].push(itemKeys[keyItem])
          }
        }
      })

      return side;
    },
    gameResultWinner(){
      if(!this.form.game_type && this.activeGameType.length > 1){
        const sideOptions = [];
        const cloneSide = cloneDeep(this.activeSide);

        for(let sideItem in cloneSide){
          const label = this.getItemTitle(cloneSide[sideItem]);

          const option = {  
            label: label,
            value: sideItem
          }

          sideOptions.push(option);
        }

        return sideOptions;
      }else{
        //如果沒有this.form.game_type 或 this.activeGameType長度小於1
        const defaultFirstGameType = this.activeGameType[0] ? this.activeGameType[0].value : '';
        //當前種類取決於this.form.game_type有沒有值
        const currentGameType = this.form.game_type !== '' ? this.form.game_type : defaultFirstGameType;
        const winnerOptions = [];
        const currentType = this.gameTypes.find(typeItem=>typeItem.value === currentGameType) || '';
        //取得config裡的遊戲種類裡面的side
        const currentSide = GAME_TYPE_SIDE[currentType.label] || {};
        for(let sideKey in currentSide){
          //如果是cancel則跳過
          if(sideKey === 'cancel') continue;
          winnerOptions.push({
            label: this.$t(`global.fight_result.${currentSide[sideKey]}`),
            value: sideKey
          })
        }
        return winnerOptions;
      }
    },
    formGameType(){
      return this.form.game_type
    },
    columns() {
      const newColumn = this.is_merchant_account ? cloneDeep(merchamtColumns) : cloneDeep(agentColumns);

      //針對game_type動態添加相應的「side」欄位
      let danamicGameColunmsIndex = [];

      const currentGameType = this.tableGameType === '' ? 'all' : this.tableGameType;

      switch(currentGameType){
        case 'all':
          //根據活躍的side去動態生成相應的欄位
          const allGameColumn = gameColunmsIndex['all'];
          const filterAllGameColumn = allGameColumn.filter(columnItem=>this.activeSide[columnItem] !== undefined);
          danamicGameColunmsIndex = filterAllGameColumn;
          break;
        case 'apple':
          danamicGameColunmsIndex = gameColunmsIndex['apple'];
          break;
        default:
          danamicGameColunmsIndex = gameColunmsIndex['normal'];
          break;
      }

      const gameTypeIndex = newColumn.findIndex(columnItem=>columnItem.slots.title === 'game_type') + 1;
      danamicGameColunmsIndex.forEach((columnIndexItem, index)=>{
        newColumn.splice(gameTypeIndex + index, 0, {
          slots: { title: 'side_' + columnIndexItem },
          dataIndex: columnIndexItem,
          scopedSlots: { customRender: 'side' },
          filterType: true,
        });
      })

      return newColumn;
    }
  },
  watch: {
    masterRequired: {
      handler(newValue) {
        if(newValue) {
          this.rules.master = [{ required: true, trigger: 'blur' }];
        } else {
          this.rules.master = [{ required: false }];
        }
      },
      immediate: true,
      deep: true
    },
    currency:{
      handler(newValue, oldValue){

        if(newValue !== ''){
          if(newValue !== this.checkCurrency){
            this.checkCurrency = newValue;
            this.form.arena_no = '';
            this.arenaList = [];
            this.fetchArenaList(newValue);
          }
        }
      }
    },
    formGameType:{
      handler(newValue){
        this.form.winner = '';
      }
    },
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const currentPage = this.pagination.pageSize !== pagination.pageSize ? 1 : pagination.current;
      this.pagination.current = currentPage;
      this.pagination.pageSize = pagination.pageSize;

      const { date, round_id, fight_id, status, date_type, arena_no, preciseTime, currency, master, game_type, side } = this.form;
      this.loading = true;
      this.fetchGameResult({
        results: pagination.pageSize,
        page: currentPage,
        date: date,
        round_id: round_id,
        fight_id: fight_id,
        status: status,
        date_type: date_type,
        arena_no: arena_no,
        preciseTime: preciseTime,
        currency: currency,
        master: master,
        game_type: game_type,
        side: side,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    async fetch(params = {}) {
      if(this.loading) return;

      this.loading = true;
      await getDashboardCard({
          type: 'GameResult',
          ...params,
        }).then(({ data }) => {
          this.total_round_amount = data.result.total_bet_amount || 0;
          this.total_round_count = data.result.total_bet_count || 0;
          this.total_round_user_count = data.result.total_bet_user_count || 0;
          this.total_round_valid_amount = data.result.total_bet_valid_amount || 0;
          this.total_platform_profit = data.result.total_platform_profit || 0;
          this.total_rate_amount = data.result.total_rate_amount || 0;
          this.loading = false;
        }).catch((err)=>{
          this.loading = false;
          var msg = this.$t(`global.message.${err.response.data.message}`);
          this.$message.error(msg)
        });

      this.fetchGameResult({...params});
    },
    async fetchGameResult(params = {}) {
      await getGameResult({
        ...params,
      }).then(({ data }) => {
        this.tableGameType = this.form.game_type;
        this.results = data.gameResults;
        this.pagination.total = data.total;
      }).finally(()=>{
        this.loading = false;
      });
    },
    onSearch() {
      if(this.loading) return;
      
      this.$refs.searchForm.validate(valid => {
        if (valid) {
          const { pageSize } = this.pagination;
          this.pagination.current = 1;
          const { date, round_id, fight_id, status, odds_type, date_type, arena_no, preciseTime, currency, master, game_type, side } = this.form;
          const only_show_total = this.dashboard_card_show;
          this.fetch({ date, round_id, fight_id, status, odds_type, results: pageSize, page: 1, date_type, arena_no, only_total: only_show_total, preciseTime, currency, master, game_type, side});
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      if(this.loading) return;
      this.$refs.searchForm.resetFields();
      this.form.slug = '';
      this.periodDefault = 'today';
      // setTimeout(() => {
      //   this.onSearch();
      // }, 100);
    },
    dateChange(value, mode) {
      this.fetch({ datetime: mode })
    },
    columnsChange(index, item) {
      item.filterType = !item.filterType;
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    fetchArenaList(currency) {
      getArenaList({ currency }).then((data) => {
        this.arenaList = filterDuplicateData(data.data.arena,'id');
      });
    },
    getItemTitle(item){
      const labels = item || [];
      
      //取得各個項目的i18n
      labels.forEach((labelItem, index)=>{
        labels[index] = this.$t(`global.fight_result.${labelItem}`)
      });

      //主文字
      let label = labels[0]

      //如果項目大於一個，在顯示上會有類似備註的文字
      if(labels.length > 1){
        label += `(${labels.slice(1, labels.length).join("/")})`
      }

      return label;

    },
    getSideTitle(title){
      const replaceTitle = title.replace('side_', '');
      const currentTypeIndex = this.tableGameType 
        ? this.gameTypes.findIndex(typeItem=>typeItem.value === this.tableGameType) 
        : null;

      if(this.tableGameType){
        return this.$t(`global.fight_result.${this.GAME_TYPE_SIDE[this.gameTypes[currentTypeIndex].label][replaceTitle]}`);
      }else{
        const cloneSide = cloneDeep(this.activeSide);
        const title = this.getItemTitle(cloneSide[replaceTitle]);

        return title;
      }
    },
  }
};
</script>
